import { database } from "../firebase";
import { ref, onValue, set } from "firebase/database";


class UsersDataService {

    create(data) {
        return set(ref(database, 'users/' + data.userName), data)
    }

    // getWebsites() { getDocs(collection(db, 'servicios')) };


}

export default new UsersDataService();
