import database from "../firebase";
import { ref, set, child, get, query, orderByChild, limitToLast, orderByKey } from "firebase/database";

// const db = firebase.ref("/ordenes");
// const db = getDatabase();
// const dbRef = ref(getDatabase());


class OrdenesDataService {
    //   getAll() {
    //     return db;
    //   }

    //   create(order) {
    //     return db.push(order);
    //   }
    getAll() {
        const dbRef = ref(database);
        const qry = query(ref(database, 'ordenes'), orderByChild('id'), limitToLast(1));
        get(child(dbRef, `ordenes/`)).then((snapshot) => {
            if (snapshot.exists()) {
                console.log(snapshot.val());
            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    getLastId() {
        const dbRef = ref(database);
        let id = 0;
        get(child(dbRef, `ordenes/`)).then((snapshot) => {
            if (snapshot.exists()) {
                snapshot.forEach((childSnapshot) => {
                    id = childSnapshot.val().id;
                });
            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });
        return id;
    }

    getOnce() {
        const qry = query(ref(database, 'ordenes'), orderByKey('id'), limitToLast(1));
        let id = 0
        get(qry).then((snapshot) => {
            if (snapshot.exists()) {
                const idObject = Object.keys(snapshot.val())[0];
                id = parseInt(idObject, 10);
            }
            return id;
        });
        return id;
        // snapshot.forEach(doc => {
        //   console.log(doc.id, '=>', doc.data());
        // });

        // const dbRef = ref(database);
        // ref(database, 'ordenes/').orderByChild('id').on('child_added', (snapshot) => {
        //     console.log(snapshot);
        //   });
        // query(ref(database, 'ordenes'), orderByChild('id'), (snapshot) => {
        //     console.log(snapshot);
        //   });
    }

    create(order, id) {
// const postListRef = ref(database, 'ordenes/');
// const newPostRef = push(postListRef);

        // return set(newPostRef, order);
        return set(ref(database, 'ordenes/' + id), order)
        // return db.push(clients);
    }

    // update(key, value) {
    //     return db.child(key).update(value);
    // }

    // delete(key) {
    //     return db.child(key).remove();
    // }

    // deleteAll() {
    //     return db.remove();
    // }
}

export default new OrdenesDataService();
