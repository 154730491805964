import React, { useState, useEffect } from "react";
import { database } from "../../firebase";
import { ref, query, orderByChild, limitToLast, onValue, set, runTransaction } from "firebase/database";
import { getFirestore, collection, addDoc, updateDoc, orderBy, getDocs, limit, setDoc, doc } from "firebase/firestore";
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

function AddCuadrilla() {
    const [state, setState] = useState({
        lastId: -2,
        numero: '',
        codigo: '',
        submitted: false,
        spinner: false,
    });

    // useEffect(() => {
    //     const topUserPostsRef = query(ref(database, 'cuadrillas'), orderByChild('id'), limitToLast(1));
    //     onValue(topUserPostsRef, (snapshot) => {
    //         const key = snapshot.val() ? Object.keys(snapshot.val()) : -2;
    //         setState(prevState => ({ ...prevState, lastId: parseInt(key, 10) }));
    //     }, {
    //         onlyOnce: true
    //     });
    // }, [state.lastId]);

    const onChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setState(prevState => ({ ...prevState, [name]: value }));
    };

    const saveCuadrillaFirestore = async () => {
        const firestore = getFirestore();

        try {
            setState(prevState => ({ ...prevState, spinner: true }));
            // Referencia a la colección de Firestore
            const cuadrillasCollectionRef = collection(firestore, "cuadrillas");

            // Consulta para obtener el documento con el ID más alto
            const q = query(cuadrillasCollectionRef, orderBy("id", "desc"), limit(1));
            const querySnapshot = await getDocs(q);

            let lastId = 0;

            // Si existe al menos un documento, obtener el último ID
            if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {
                    lastId = doc.data().id;
                });
            }

            // Generar el nuevo ID sumando 1 al último ID
            const newId = lastId + 1;

            // Nuevo registro con el nuevo ID
            const newCuadrillaData = {
                numero: state.numero,
                codigo: state.codigo,
                id: newId
            };

            // Guardar el nuevo documento en Firestore con el nuevo ID
            await setDoc(doc(firestore, "cuadrillas", newId.toString()), newCuadrillaData);
            setState(
                prevState => ({
                    ...prevState,
                    submitted: true,
                    spinner: false
                }));

            console.log("Nuevo documento guardado con éxito");
        } catch (error) {
            console.error("Error al guardar el nuevo documento: ", error);
        }
    };

    const saveCuadrilla = () => {
        let lastId = state.lastId;

        const topUserPostsRef = query(ref(database, 'cuadrillas'), orderByChild('id'), limitToLast(1));
        onValue(topUserPostsRef, (snapshot) => {
            const key = snapshot.val() ? Object.keys(snapshot.val()) : -2;
            lastId = parseInt(key, 10);
            setState(prevState => ({ ...prevState, spinner: true }));
        }, {
            onlyOnce: true
        });

        setTimeout(() => {
            let data = {
                id: lastId + 1,
                numero: state.numero,
                codigo: state.codigo,
            };
            set(ref(database, 'cuadrillas/' + data.id), data)
                .then(() => {
                    setState(
                        prevState => ({
                            ...prevState,
                            submitted: true,
                            spinner: false
                        }));
                })
                .catch((e) => {
                    console.log(e);
                });
        }, 1500);
    }

    const newProduct = () => {
        setState({
            // id: id,
            numero: '',

            submitted: false,
        });
    }

    return (
        <Container component="main" maxWidth="xs">
            {state.submitted ? (
                <div>
                    <Typography variant="h4">Cuadrilla creada correctamente!</Typography>
                    {/* <Button variant="contained" onClick={newProduct}>Nuevo</Button> */}
                    <Button href="/cuadrillas">
                        Ir a listado
                    </Button>
                </div>
            ) : (
                <div className="form-container">
                    {state.spinner ? (
                        <CircularProgress />
                    ) : (
                        <>
                            <Typography component="h1" variant="h5">
                                Nueva Cuadrilla
                            </Typography>
                            <div className="order-container">
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            className="default__textfield"
                                            id="numero"
                                            label="Numero / Nombre"
                                            value={state.numero}
                                            name="numero"
                                            onChange={onChangeValues}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            className="default__textfield"
                                            id="codigo"
                                            label="Código"
                                            value={state.codigo}
                                            name="codigo"
                                            onChange={onChangeValues}
                                        />
                                    </Grid>
                                </Grid>
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className="button__save"
                                    onClick={saveCuadrillaFirestore}
                                >
                                    Aceptar
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            )}
        </Container>
    );
}

export default AddCuadrilla;
