import { getFirestore, collection, query, where, getDocs, orderBy, doc, updateDoc } from "firebase/firestore";
const firestore = getFirestore();

export const getCuadrillasOnce = async (onDataChange) => {
    try {
        const cuadrillasCollection = collection(firestore, 'cuadrillas');
        const queryCuadrillas = query(cuadrillasCollection, orderBy('id', 'asc'));
        const snapshot = await getDocs(queryCuadrillas);
        const data = snapshot.docs.map(doc => doc.data());
        onDataChange(data);
    } catch (error) {
        console.error("Error al obtener los datos de cuadrillas:", error);
    }
};

export const getCuadrillaById = async (id, onDataChange) => {
    const cuadrillasCollection = collection(firestore, 'cuadrillas');
    const q = query(cuadrillasCollection, where('id', '==', id));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
            onDataChange(doc.data())
        });
    } else {
        console.log("No se encontró ningún documento con ese id.");
    }
}

export const updateCuadrillaFirestore = async (id, data, functionExe) => {
    try {

        const docRef = doc(firestore, "cuadrillas", String(id));

        // Actualizar solo los campos que especifiques
        await updateDoc(docRef, data);

        console.log("Documento actualizado exitosamente");
        if (functionExe) {
            functionExe();
        }
    } catch (error) {
        console.error("Error al actualizar el documento: ", error);
    }
};