import { getFirestore, collection, query, where, getDocs, orderBy, doc, updateDoc } from "firebase/firestore";
const firestore = getFirestore();

export const getMaterialesOnce = async (onDataChange) => {
    try {
        const materialesCollection = collection(firestore, 'materiales');
        const queryMateriales = query(materialesCollection, orderBy('id', 'asc'));
        const snapshot = await getDocs(queryMateriales);
        const data = snapshot.docs.map(doc => doc.data());

        // Verificamos si el estado actual es un array o un objeto.
        onDataChange((prevState) => {
            if (Array.isArray(prevState)) {
                // Si el estado previo es un array, asumimos que es para setMaterials.
                return data; // Sustituimos completamente el estado.
            } else if (typeof prevState === 'object' && prevState !== null) {
                // Si el estado previo es un objeto, asumimos que es para setState.
                return { ...prevState, materials: data }; // Actualizamos solo la propiedad `materials`.
            } else {
                console.error("Tipo de estado no compatible para `onDataChange`.");
                return prevState; // Devolvemos el estado sin modificar en caso de error.
            }
        });
    } catch (error) {
        console.error("Error al obtener los datos de materiales:", error);
    }
};
