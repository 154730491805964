import React, { useEffect, useState } from "react";
import AES from "crypto-js/aes";
import CryptoJS from "crypto-js";
import { database } from "../../firebase";
import { ref, onValue, query, orderByChild, equalTo } from "firebase/database";
// import { Toast } from "antd-mobile";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import LockIcon from '@mui/icons-material/Lock';
import { passKey } from "../../utils/default";

function SignIn() {
  const [state, setState] = useState({
    email: '',
    password: '',
  })
  const [error, setError] = useState(false)

  useEffect(() => {
    localStorage.removeItem('currentUser');
  }, [])

  const onChangeValues = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  const getUser = () => {
    const cuadrilla = query(ref(database, 'users'), orderByChild('email'), equalTo(state.email));
    onValue(cuadrilla, (snapshot) => {
      if (snapshot.val() !== null) {
        verifyLogin(Object.values(snapshot.val()))
      } else {
        setError(true)
      }
    }, {
      onlyOnce: true
    });
  }

  const verifyLogin = (user) => {
    const pass = user[0].password;
    const actualPass = state.password;
    const bytes = CryptoJS.AES.decrypt(pass, passKey);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    if (actualPass === originalText) {
      setError(false)
      const data = {
        userName: user[0].userName,
        email: user[0].email,
        rol: user[0].rol,
      }
      localStorage.setItem('currentUser', JSON.stringify(data));
      window.location = "/";
    } else {
      setError(true)
    }
  }

    return (
      <Container component="main" maxWidth="xs">
        <div className="form-container">
          <Avatar className="avatar">
            <LockIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Iniciar Sesión
          </Typography>
          {error && (
            <Alert sx={{margin: '10px 0'}} severity="error"> El usuario o la contraseña son incorrectos</Alert>
          )}
          <div className="login-container">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  onChange={onChangeValues}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="password"
                  label="Contraseña"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={onChangeValues}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Recordarme"
                />
              </Grid>
            </Grid>
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className="button__save"
              onClick={getUser}
            >
              Iniciar Sesión
            </Button>
            {/* <Grid container justify="center">
              <Grid item>
                <Link href="/register" variant="body2">
                  No tienes una cuenta? Regístrate
                </Link>
              </Grid>
            </Grid> */}
          </div>
        </div>
        <Box mt={5}>
          <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit">
              TopTec
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </Container>
    );
  }

export default SignIn;
