import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { database } from "../../firebase";
import { ref, query, orderByChild, equalTo, onValue, update } from "firebase/database";
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import { unidadMedida } from "../../utils/default";

function EditPrices() {
    let { id } = useParams();
    const [state, setState] = useState({
        id: id,
        descripcion: '',
        unidad_medida: "",
        hogar: "",
        corporativo: "",
        feriado_domingo_hogar: "",
        feriado_domingo_corporativo: "",
        submitted: false,
    });
    const [keyPrice, setKeyPrice] = useState('');

    useEffect(() => {
        const topUserPostsRef = query(ref(database, 'precios'), orderByChild('id'), equalTo(parseInt(state.id, 10)));
        onValue(topUserPostsRef, (snapshot) => {
            setValues(Object.values(snapshot.val()));
        }, {
            onlyOnce: true
        });
    }, []);

    const setValues = (values) => {
        const keyObject = Object.keys(values);
        setKeyPrice(keyObject[0]);
        if (values instanceof Array) {
        values.forEach(val => {
            setState(prevState => ({ 
                ...prevState, 
                id: val.id, 
                descripcion: val.descripcion,
                unidad_medida: val.unidad_medida,
                hogar: val.hogar,
                corporativo: val.corporativo,
                feriado_domingo_hogar: val.feriado_domingo_hogar,
                feriado_domingo_corporativo: val.feriado_domingo_corporativo,
            }));
        })
    } else {
        const valuesProf = values[keyObject]
            setState(prevState => ({ 
                ...prevState, 
                id: valuesProf.id, 
                descripcion: valuesProf.descripcion,
                unidad_medida: valuesProf.unidad_medida,
                hogar: valuesProf.hogar,
                corporativo: valuesProf.corporativo,
                feriado_domingo_hogar: valuesProf.feriado_domingo_hogar,
                feriado_domingo_corporativo: valuesProf.feriado_domingo_corporativo,
            }));  
        }
    }

    const onChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setState(prevState => ({ ...prevState, [name]: value }));
    };

    const onChangeUnidadMedida = (e) => {
        setState(prevState => ({ ...prevState, unidad_medida: e.target.value }));
    }

    const updatePrices = () => {
        let data = {
            id: state.id,
            descripcion: state.descripcion,
            unidad_medida: state.unidad_medida,
            hogar: state.hogar,
            corporativo: state.corporativo,
            feriado_domingo_hogar: state.feriado_domingo_hogar,
            feriado_domingo_corporativo: state.feriado_domingo_corporativo,
        };

        update(ref(database, 'precios/' + state.id), data)
            .then(() => {
                setState({
                    submitted: true,
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    const newProduct = () => {
        setState({
            id: id,
            descripcion: '',
            unidad_medida: "",
            hogar: "",
            corporativo: "",
            feriado_domingo_hogar: "",
            feriado_domingo_corporativo: "",

            submitted: false,
        });
    }

    return (
        <Container component="main" maxWidth="xs">
            {state.submitted ? (
                <div>
                    <Typography variant="h4">Precios editados correctamente!</Typography>
                    {/* <Button variant="contained" onClick={newProduct}>Nuevo</Button> */}
                    <Button href="/prices">
                        Ir a listado
                    </Button>
                </div>
            ) : (
                <div className="form-container">
                    <Typography component="h1" variant="h5">
                        Editar Precios
                    </Typography>
                    <div className="order-container">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    className="default__textfield"
                                    id="descripcion"
                                    label="Descripción"
                                    value={state.descripcion}
                                    name="descripcion"
                                    onChange={onChangeValues}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel>Tipo Cliente</InputLabel>
                                <Select
                                    onChange={onChangeUnidadMedida}
                                    value={state.unidad_medida}
                                    className="select__form"
                                    fullWidth
                                >
                                    {unidadMedida.map((tipo) => (
                                        <MenuItem key={tipo} value={tipo}>
                                            {tipo}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    className="default__textfield"
                                    id="hogar"
                                    label="Hogar"
                                    value={state.hogar}
                                    name="hogar"
                                    onChange={onChangeValues}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    className="default__textfield"
                                    id="corporativo"
                                    label="Corporativo"
                                    value={state.corporativo}
                                    name="corporativo"
                                    onChange={onChangeValues}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    className="default__textfield"
                                    id="feriado_domingo_hogar"
                                    label="Feriado/Domingo (Hogar)"
                                    value={state.feriado_domingo_hogar}
                                    name="feriado_domingo_hogar"
                                    onChange={onChangeValues}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    className="default__textfield"
                                    id="feriado_domingo_corporativo"
                                    label="Feriado/Domingo (Corporativo)"
                                    value={state.feriado_domingo_corporativo}
                                    name="feriado_domingo_corporativo"
                                    onChange={onChangeValues}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className="button__save"
                            onClick={updatePrices}
                        >
                            Aceptar
                        </Button>
                    </div>
                </div>
            )}
        </Container>
    );
}

export default EditPrices;
