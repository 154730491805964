// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore/lite";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAG8bGJJ-Y5m_58TT6eZ5MCBr-uFLZbVx0",
  authDomain: "personal-gestion.firebaseapp.com",
  projectId: "personal-gestion",
  storageBucket: "personal-gestion.appspot.com",
  messagingSenderId: "741209957699",
  appId: "1:741209957699:web:47c4c7c459f276f74abbf6",
  measurementId: "G-NW53H38KXB",
  databaseURL: "https://personal-gestion-default-rtdb.firebaseio.com",
};


const app = initializeApp(firebaseConfig);

// Initialize Realtime Database and get a reference to the service
export const database = getDatabase(app);
export const firestore = getFirestore(app);
// export const db = getFirestore();
// Initialize Firebase
// const provider = new firebase.auth.GoogleAuthProvider();
// export const auth = firebase.auth();
export default database;
// export const firestore = firebase.firestore();
// const analytics = getAnalytics(app);