import React, { useState, useEffect } from "react";
import { database } from "../../firebase";
import { ref, onValue, query, orderByChild, set, update, equalTo, limitToLast } from "firebase/database";
import { getFirestore, collection, addDoc, updateDoc, orderBy, getDocs, limit, setDoc, doc, where, query as queryFire } from "firebase/firestore";
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';
import { read, utils, write } from 'xlsx';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import { tipoRemito, tipoDeco } from "../../utils/default";
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { idCuadrillaNucleo } from '../../utils/default';
import FacturaRemito from "./FacturaRemito";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { getCuadrillasOnce, updateCuadrillaFirestore } from "../../services/cuadrillasService";
import { getMaterialesOnce } from "../../services/materialsService";
import { Typography } from "@mui/material";

const firestore = getFirestore();

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AddRemito() {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const [serialesIndex, setSerialesIndex] = useState(0)
    const [state, setState] = useState({
        lastId: -1,
        materials: [],
        materialsToSave: [],
        serialesToSave: [],
        controlsToSave: [],
        cuadrillaEntrega: '',
        cuadrillaReceptora: '',
        tipoRemito: 'ENTREGA',
        date: moment(new Date().getTime()),
        observaciones: '',
    })
    const [serialesToSelect, setSerialesToSelect] = useState([]);
    const [serialesCuadrillaDestino, setSerialesCuadrillaDestino] = useState([]);
    const [controlsDestino, setControlsDestino] = useState([]);
    const [controlsEntrega, setControlsEntrega] = useState([]);
    const [cuadrillas, setCuadrillas] = useState([]);
    let [newSerialesEntrega, setNewSerialesEntrega] = useState([]);
    const [openModalSee, setOpenModalSee] = useState(false);
    const [openModalAdd, setOpenModalAdd] = useState(false);
    const [snackbarInfo, setSnackbarInfo] = useState({ text: '', type: '', open: false });
    const [openPreviewModal, setOpenPreviewModal] = useState(false);
    const [materialsCuadrillaReceptora, setMaterialsCuadrillaReceptora] = useState([]);
    // const [controlesSaveReceptora, setControlesSaveReceptora] = useState([]);
    const [spinner, setSpinner] = useState(false)
    const [saveOk, setSaveOk] = useState(-1)



    const onChangeValues = (e, index) => {
        const value = e?.target.value;
        const name = e?.target.name;
        if (state.serialesToSave[index]) {
            state.serialesToSave[index][name] = value
        } else {
            const newValue = { [name]: value }
            state.serialesToSave[index] = newValue
        }
        setState(prevState => ({ ...prevState }))
    };

    const onAddBtnClick = (e, type) => {
        e.preventDefault();
        setSerialConfigList(serialConfigList.concat(inputSerials));
        setSerialesIndex(serialConfigList.length)
    };

    const onChangeTipoSerial = (e, tipo, index) => {
        e.preventDefault();
        const value = tipo;
        if (state.serialesToSave[index]) {
            state.serialesToSave[index].tipo = value
        } else {
            const newTipo = { tipo: value }
            state.serialesToSave[index] = newTipo
        }
        setState(prevState => ({ ...prevState }))
    }

    const onChangeSelectCuadrilla = (event, cuadrilla) => {
        const nroCuadrilla = cuadrilla.numero;
        const idCuadrilla = cuadrilla.id;
        setState(prevState => ({ ...prevState, cuadrilla: nroCuadrilla, idCuadrilla }));
    }

    const inputSerials = (index, serialCharge) => [
        <>
            <FormControl key={index} sx={{ m: 1, width: '25%' }}>
                {/* <InputLabel id="select-label-remito">Tipo</InputLabel>
                <Select
                    label="Tipo"
                    onChange={(e) => onChangeTipoSerial(e, index)}
                    value={serialCharge !== undefined ? serialCharge?.tipo : ''}
                    className="select__form"
                    input={<OutlinedInput label="Tipo Remito" />}
                >
                    {tipoDeco.map((tipo) => (
                        <MenuItem key={tipo} value={tipo}>
                            {tipo.toUpperCase()}
                        </MenuItem>
                    ))}
                </Select> */}
                <Autocomplete
                    options={tipoDeco}
                    id="disable-clearable"
                    value={serialCharge?.tipo || ''}
                    onChange={(e, tipo) => onChangeTipoSerial(e, tipo, index)}
                    getOptionLabel={(option) => option?.toUpperCase()}
                    isOptionEqualToValue={(option, value) => option === value || value === ''}
                    fullWidth
                    renderInput={(params) => (
                        <TextField {...params} label="Tipo" placeholder="Tipo" />
                    )}
                />
            </FormControl>
            <TextField
                id="outlined-number"
                label="Serial"
                disabled={!state?.serialesToSave[index]?.tipo}
                className="text-form__serial"
                onChange={(e) => onChangeValues(e, index)}
                name="id"
                InputLabelProps={{
                    shrink: true,
                }}
                sx={{ margin: '8px 10px 8px 0', width: '30%' }}
                defaultValue={serialCharge?.id || ''}
            />
            <TextField
                id="outlined-number"
                label="Observaciones"
                disabled={!state?.serialesToSave[index]?.tipo}
                className="text-form__serial"
                onChange={(e) => onChangeValues(e, index)}
                name="observaciones"
                InputLabelProps={{
                    shrink: true,
                }}
                sx={{ margin: '8px 10px 8px 0', width: '38%' }}
                defaultValue={serialCharge?.observaciones || ''}
            />
        </>
    ]

    const [serialConfigList, setSerialConfigList] = useState(inputSerials);

    useEffect(() => {
        // onValue(ref(database, '/materiales/'), (snapshot) => {
        //     setState(prevState => ({ ...prevState, materials: snapshot.val() }));
        // }, {
        //     onlyOnce: true
        // });
        getMaterialesOnce(setState);

        const topUserPostsRef = query(ref(database, 'remitos'), orderByChild('id'), limitToLast(1));
        onValue(topUserPostsRef, (snapshot) => {
            const key = snapshot.val() ? Object.keys(snapshot.val()) : 0;
            setState(prevState => ({ ...prevState, lastId: parseInt(key, 10) }));
        }, {
            onlyOnce: true
        });
        // onValue(ref(database, '/cuadrillas/'), (snapshot) => {
        //     setCuadrillas(Object.values(snapshot.val()))
        // }, {
        //     onlyOnce: true
        // });
        getCuadrillasOnce(setCuadrillas)
    }, []);

    const changeInput = (e, id, name) => {
        const value = e?.target.value;
        const formatData = { id, name, value }
        const filterService = state.materialsToSave.filter(service => service?.id === id);
        let newData = [];
        const keys = Object.keys(state.materialsToSave);
        if (filterService.length > 0) {
            let materialNewArray = [...state.materialsToSave]
            const keyService = keys.filter(key => state.materialsToSave[key]?.id === id);
            let materialToEdit = { ...materialNewArray[keyService] };
            materialToEdit.value = value
            materialNewArray[keyService] = materialToEdit
            setState(prevState => ({ ...prevState, materialsToSave: materialNewArray }));
        } else {
            newData = [...state.materialsToSave, formatData]
            setState(prevState => ({ ...prevState, materialsToSave: newData }));
        }

        if (value === '') {
            let materialNewArray = [...state.materialsToSave]
            const keyService = keys.filter(key => state.materialsToSave[key]?.id === id);
            materialNewArray.splice(parseInt(keyService[0], 10), 1);
            setState(prevState => ({ ...prevState, materialsToSave: materialNewArray }));
        }
    }

    const changeObservationsMaterials = (e, id) => {
        const filterService = state.materialsToSave.filter(service => service?.id === id);
        const keys = Object.keys(state.materialsToSave);
        if (filterService.length > 0) {
            let materialNewArray = [...state.materialsToSave]
            const keyService = keys.filter(key => state.materialsToSave[key]?.id === id);
            let materialToEdit = { ...materialNewArray[keyService] };
            materialToEdit.observaciones = e?.target.value
            materialNewArray[keyService] = materialToEdit
            setState(prevState => ({ ...prevState, materialsToSave: materialNewArray }));
        }
    }

    const onChangeDate = (value) => {
        setState(prevState => ({ ...prevState, date: value }));
    };

    const getCuadrillasDyO = async (idCuadrilla, setCuadrillaState, setControlState) => {
        const cuadrillasCollection = collection(firestore, 'cuadrillas');
        const q = queryFire(cuadrillasCollection, where('id', '==', idCuadrilla));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
                const serials = doc.data()?.seriales || [];
                const controles = doc.data()?.controles || [];
                setCuadrillaState(serials)
                setControlState(controles)
            });
        } else {
            console.log("No se encontró ningún documento con ese id.");
        }
    }

    const onChangeCuadrillaDestino = async (event, cuadrilla) => {
        const idCuadrilla = cuadrilla.id;
        setState(prevState => ({ ...prevState, cuadrillaReceptora: idCuadrilla }));
        getCuadrillasDyO(idCuadrilla, setSerialesCuadrillaDestino, setControlsDestino)
        // const receptora = query(ref(database, 'cuadrillas'), orderByChild('id'), equalTo(idCuadrilla));
        // onValue(receptora, (snapshot) => {
        //     const serials = Object.values(snapshot.val())[0]?.seriales || [];
        //     const controles = Object.values(snapshot.val())[0]?.controles || [];
        //     setSerialesCuadrillaDestino(serials)
        //     setControlsDestino(controles)
        // }, {
        //     onlyOnce: true
        // });
        const cuadrillaReceptora = query(ref(database, 'materialsxcuadrilla'), orderByChild('idCuadrilla'), equalTo(idCuadrilla));
        onValue(cuadrillaReceptora, (snapshot) => {
            const materialsCuadRec = snapshot.val() ? Object.values(snapshot.val()) : [];
            setMaterialsCuadrillaReceptora(materialsCuadRec);
        }, {
            onlyOnce: true
        });
    };

    const onChangeCuadrillaEntrega = async (event, cuadrilla) => {
        const idCuadrilla = cuadrilla.id;
        setState(prevState => ({ ...prevState, cuadrillaEntrega: idCuadrilla }));
        getCuadrillasDyO(idCuadrilla, setSerialesToSelect, setControlsEntrega)

        // const bodega = query(ref(database, 'cuadrillas'), orderByChild('id'), equalTo(idCuadrilla));
        // onValue(bodega, (snapshot) => {
        //     const serials = Object.values(snapshot.val())[0]?.seriales || [];
        //     const controles = Object.values(snapshot.val())[0]?.controles || [];
        //     setSerialesToSelect(serials)
        //     setControlsEntrega(controles)
        // }, {
        //     onlyOnce: true
        // });

    };

    const onChangeTipoRemito = (e) => {
        setState(prevState => ({ ...prevState, tipoRemito: e?.target.value }));
    }

    const onChangeSeriales = (event, serialsSelected) => {
        setState(prevState =>
        ({
            ...prevState,
            serialesToSave: serialsSelected,
        }));
    }

    const onChangeObservationSeriales = (event, serial) => {
        const value = event.target.value;

        const indexSerial = state.serialesToSave.findIndex((obj) => obj?.id === serial);
        state.serialesToSave[indexSerial].observaciones = value

        setState(prevState => ({ ...prevState }));
    }

    const onChangeControles = (e, id, name) => {
        const value = parseInt(e?.target.value, 10)
        const formatData = { id, name, value }
        const filterService = state.controlsToSave.filter(service => service?.id === id);
        let newData = [];
        const keys = Object.keys(state.controlsToSave);
        if (filterService.length > 0) {
            let materialNewArray = [...state.controlsToSave]
            const keyService = keys.filter(key => state.controlsToSave[key]?.id === id);
            let materialToEdit = { ...materialNewArray[keyService] };
            materialToEdit.value = value
            materialNewArray[keyService] = materialToEdit
            setState(prevState => ({ ...prevState, controlsToSave: materialNewArray }));
        } else {
            newData = [...state.controlsToSave, formatData]
            setState(prevState => ({ ...prevState, controlsToSave: newData }));
        }
    };

    const formatRemitoToSave = (lastId) => {
        const nameCuadrillaEntrega = cuadrillas.filter(cuad => cuad?.id === state.cuadrillaEntrega)[0]?.numero;
        const nameCuadrillaReceptora = cuadrillas.filter(cuad => cuad?.id === state.cuadrillaReceptora)[0]?.numero;


        const dataRemitoToSave = {
            id: lastId + 1,
            materials: state.materialsToSave,
            serials: state.serialesToSave,
            tipoRemito: state.tipoRemito,
            controles: state.controlsToSave,
            fecha: moment(state.date).format("DD/MM/YYYY"),
            cuadrillaEntrega: nameCuadrillaEntrega,
            cuadrillaReceptora: nameCuadrillaReceptora,
            idCuadrillaEntrega: state.cuadrillaEntrega,
            idCuadrillaReceptora: state.cuadrillaReceptora,
            observaciones: state.observaciones,
        };

        return dataRemitoToSave;
    }

    const saveOnlyRemito = async (dataRemitoToSave) => {
        const firestore = getFirestore();

        try {
            // Referencia a la colección de Firestore
            const remitosCollectionRef = collection(firestore, "remitos");

            // Consulta para obtener el documento con el ID más alto
            const q = query(remitosCollectionRef, orderBy("id", "desc"), limit(1));
            const querySnapshot = await getDocs(q);

            let lastId = 0;

            // Si existe al menos un documento, obtener el último ID
            if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {
                    lastId = doc.data().id;
                });
            }

            // Generar el nuevo ID sumando 1 al último ID
            const newId = lastId + 1;

            // Nuevo registro con el nuevo ID
            dataRemitoToSave.id = newId;

            // Guardar el nuevo documento en Firestore con el nuevo ID
            await setDoc(doc(firestore, "remitos", newId.toString()), dataRemitoToSave);

            console.log("Nuevo documento guardado con éxito");
            setSpinner(false);
            setSaveOk(newId)
            // setTimeout(() => {
            //     window.location.href = `/imprimir/${dataRemitoToSave?.newId}`
            // }, 5000);

        } catch (error) {
            console.error("Error al guardar el nuevo documento: ", error);
        }
    };

    const saveRemito = () => {
        setOpenPreviewModal(false);
        let lastId = state.lastId;

        const topUserPostsRef = query(ref(database, 'remitos'), orderByChild('id'), limitToLast(1));
        onValue(topUserPostsRef, (snapshot) => {
            const key = snapshot.val() ? Object.keys(snapshot.val()) : 0;
            lastId = parseInt(key, 10);
            setSpinner(true);
        }, {
            onlyOnce: true
        });
        const nameCuadrillaEntrega = cuadrillas.filter(cuad => cuad?.id === state.cuadrillaEntrega)[0]?.numero;
        const nameCuadrillaReceptora = cuadrillas.filter(cuad => cuad?.id === state.cuadrillaReceptora)[0]?.numero;

        // Llamadas

        // SE HACE LA LLAMADA ANTES EN EL METODO onChangeCuadrillaReceptora
        // let materialsCuadrillaReceptora = [];
        // const cuadrillaReceptora = query(ref(database, 'materialsxcuadrilla'), orderByChild('idCuadrilla'), equalTo(parseInt(state.cuadrillaReceptora, 10)));
        // onValue(cuadrillaReceptora, (snapshot) => {
        //     const materialsCuadRec = snapshot.val() ? Object.values(snapshot.val()) : [];
        //     materialsCuadrillaReceptora = materialsCuadRec;
        // }, {
        //     onlyOnce: true
        // });

        const cuadrillaEmisora = query(ref(database, 'materialsxcuadrilla'), orderByChild('idCuadrilla'), equalTo(parseInt(state.cuadrillaEntrega, 10)));
        let materialsCuadrillaEmisora = [];
        onValue(cuadrillaEmisora, (snapshot) => {
            const materialsCuadEm = snapshot.val() ? Object.values(snapshot.val()) : [];
            materialsCuadrillaEmisora = materialsCuadEm;
        }, {
            onlyOnce: true
        });

        let controlesCuadrillaReceptora = controlsDestino;
        let serialsCuadrillaReceptora = serialesCuadrillaDestino;

        // Escritura
        setTimeout(() => {

            let dataRemitoToSave = {
                // id: lastId + 1,
                materials: state.materialsToSave,
                serials: state.serialesToSave,
                tipoRemito: state.tipoRemito,
                controles: state.controlsToSave,
                fecha: moment(state.date).format("DD/MM/YYYY"),
                cuadrillaEntrega: nameCuadrillaEntrega,
                cuadrillaReceptora: nameCuadrillaReceptora,
                idCuadrillaEntrega: state.cuadrillaEntrega,
                idCuadrillaReceptora: state.cuadrillaReceptora,
                observaciones: state.observaciones,
            };
            saveOnlyRemito(dataRemitoToSave)
            // set(ref(database, 'remitos/' + dataRemitoToSave?.id), dataRemitoToSave)
            //     .then(() => {
            //         console.log('ok remitos rec')
            //     })
            //     .catch(() => setSnackbarInfo({ text: 'Ocurrió un error al guardar el remito', type: 'error', open: true }))

            state.materialsToSave.forEach(mate => {
                const filterMaterialsByCuadrilla = materialsCuadrillaReceptora.filter(
                    cuadrilla => cuadrilla?.idMaterial === parseInt(mate?.id, 10));

                if (filterMaterialsByCuadrilla.length > 0) {
                    filterMaterialsByCuadrilla.forEach(matxcuad => {
                        // const quantityStock = state.tipoRemito === 'ENTREGA' ? matxcuad.cantidad + parseInt(mate.value, 10) : matxcuad.cantidad - parseInt(mate.value, 10);
                        const quantityStock = matxcuad.cantidad + parseInt(mate.value, 10);
                        return (
                            update(ref(database, 'materialsxcuadrilla/' + matxcuad?.idCuadrilla + 'c' + matxcuad?.idMaterial),
                                { cantidad: quantityStock })
                                .then(() => {
                                    console.log('ok materialsxcuadrilla rec')
                                })
                                .catch((e) => {
                                    setSnackbarInfo({ text: 'Ocurrió un error al guardar los materiales', type: 'error', open: true })
                                })
                        )
                    })
                } else {
                    const idCuadrilla = parseInt(state.cuadrillaReceptora, 10);
                    const idMaterial = parseInt(mate?.id, 10);
                    const dataToStock = {
                        // cantidad: state.tipoRemito === 'ENTREGA' ? parseInt(mate.value, 10) : -parseInt(mate.value, 10),
                        cantidad: parseInt(mate.value, 10),
                        idCuadrilla,
                        idMaterial
                    }
                    set(ref(database, 'materialsxcuadrilla/' + idCuadrilla + 'c' + idMaterial), dataToStock)
                        .then(() => {
                            console.log('ok materialsxcuadrilla rec');
                        })
                        .catch(() => setSnackbarInfo({ text: 'Ocurrió un error al guardar los materiales', type: 'error', open: true }))
                }
            });

            state.materialsToSave.forEach(mate => {
                const filterMaterialsByCuadrilla = materialsCuadrillaEmisora.filter(
                    cuadrilla => cuadrilla?.idMaterial === parseInt(mate?.id, 10));

                if (filterMaterialsByCuadrilla.length > 0) {
                    filterMaterialsByCuadrilla.forEach(matxcuad => {
                        // const quantityStock = state.tipoRemito === 'ENTREGA' ? matxcuad.cantidad + parseInt(mate.value, 10) : matxcuad.cantidad - parseInt(mate.value, 10);
                        const quantityStock = matxcuad.cantidad - parseInt(mate.value, 10);
                        return (
                            update(ref(database, 'materialsxcuadrilla/' + matxcuad?.idCuadrilla + 'c' + matxcuad?.idMaterial),
                                { cantidad: quantityStock })
                                .then(() => {
                                    console.log('ok materialesxc emisora', quantityStock)
                                })
                                .catch((e) => {
                                    setSnackbarInfo({ text: 'Ocurrió un error al guardar los materiales', type: 'error', open: true })
                                })
                        )
                    })
                } else {
                    const idCuadrilla = parseInt(state.cuadrillaEntrega, 10);
                    const idMaterial = parseInt(mate?.id, 10);
                    const dataToStock = {
                        // cantidad: state.tipoRemito === 'ENTREGA' ? parseInt(mate.value, 10) : -parseInt(mate.value, 10),
                        cantidad: -parseInt(mate.value, 10),
                        idCuadrilla,
                        idMaterial
                    }
                    set(ref(database, 'materialsxcuadrilla/' + idCuadrilla + 'c' + idMaterial), dataToStock)
                        .then(() => {
                            console.log('ok materialsxcuadrilla emisora')
                        })
                        .catch(() => setSnackbarInfo({ text: 'Ocurrió un error al guardar los materiales', type: 'error', open: true }))
                }
            });

            // setTimeout(() => {
            const formatSerialsToSave = serialsCuadrillaReceptora.concat(state.serialesToSave)
            state.controlsToSave.forEach(control => {
                const filterTipoControl = controlesCuadrillaReceptora.filter(cont => cont?.id === control?.id)
                let newControlSave = [];
                const keys = Object.keys(controlesCuadrillaReceptora);
                if (filterTipoControl.length > 0) {
                    let materialNewArray = [...controlesCuadrillaReceptora]
                    const keyService = keys.filter(key => controlesCuadrillaReceptora[key]?.id === control?.id);
                    let materialToEdit = { ...materialNewArray[keyService] };
                    materialToEdit.value += control.value
                    materialNewArray[keyService] = materialToEdit
                    controlesCuadrillaReceptora = materialNewArray;
                } else {
                    newControlSave = [...controlesCuadrillaReceptora, control]
                    controlesCuadrillaReceptora = newControlSave;
                }
            })

            const dataUpdateCuadrillaReceptora = { seriales: formatSerialsToSave, controles: controlesCuadrillaReceptora }
            updateCuadrillaFirestore(state.cuadrillaReceptora, dataUpdateCuadrillaReceptora)
            // update(ref(database, 'cuadrillas/' + state.cuadrillaReceptora),
            //     { seriales: formatSerialsToSave, controles: controlesCuadrillaReceptora })
            //     .then(() => {
            //         console.log('ok cuadrillas receptora', formatSerialsToSave, controlesCuadrillaReceptora)
            //     })
            //     .catch((e) => {
            //         setSnackbarInfo({ text: 'Ocurrió un error al guardar los seriales', type: 'error', open: true })
            //     })

            const indexSeriales = state.serialesToSave.map(serial => (
                serialesToSelect.findIndex((obj) => obj?.id === serial?.id)
            ))
            let newArrayCuadrilla = serialesToSelect;
            newArrayCuadrilla = newArrayCuadrilla?.filter((value, index) => indexSeriales.indexOf(index) === -1);
            newSerialesEntrega = newArrayCuadrilla || [];
            setNewSerialesEntrega(newSerialesEntrega);

            let newArrayControlsEntrega = controlsEntrega;

            if (state.controlsToSave.length > 0) {
                state.controlsToSave.forEach(control => {
                    const filterTipoControl = newArrayControlsEntrega.filter(cont => cont?.id === control?.id)
                    let newControlSave = [];
                    const keys = Object.keys(newArrayControlsEntrega);
                    if (filterTipoControl.length > 0) {
                        let materialNewArray = [...newArrayControlsEntrega]
                        const keyService = keys.filter(key => newArrayControlsEntrega[key]?.id === control?.id);
                        let materialToEdit = { ...materialNewArray[keyService] };
                        materialToEdit.value -= control.value
                        materialNewArray[keyService] = materialToEdit
                        newArrayControlsEntrega = materialNewArray
                    } else {
                        const formatControl = {
                            id: control?.id,
                            name: control.name,
                            value: -control.value
                        }
                        newControlSave = [...newArrayControlsEntrega, formatControl]
                        newArrayControlsEntrega = newControlSave
                    }
                })
            }

            const dataUpdateCuadrillaEntrega = { seriales: newSerialesEntrega, controles: newArrayControlsEntrega }
            updateCuadrillaFirestore(state.cuadrillaEntrega, dataUpdateCuadrillaEntrega)

            // update(ref(database, 'cuadrillas/' + state.cuadrillaEntrega),
            //     { seriales: newSerialesEntrega, controles: newArrayControlsEntrega })
            //     .then(() => {
            //         console.log('ok cuadrillas entrega', newSerialesEntrega, newArrayControlsEntrega)
            // setTimeout(() => {
            //     window.location.href = `/imprimir/${dataRemitoToSave?.id}`
            // }, 5000);
            //     })
            //     .catch((e) => {
            //         setSnackbarInfo({ text: 'Ocurrió un error al guardar los seriales', type: 'error', open: true })
            //     })
            // }, 500);
        }, 1500);
    }

    const handleImport = ($event) => {
        const files = $event.target.files;
        if (files.length) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;

                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                    setState(prevState => ({ ...prevState, serialesToSave: rows }));
                }
            }
            reader.readAsArrayBuffer(file);
        }
    }

    const handleClose = () => {
        setOpenModalSee(false);
        setOpenModalAdd(false);
    };

    const isValidSerial = (serial) => {
        let isValid = false;
        if (serial?.tipo && serial?.id && serial?.id !== '') {
            isValid = true
        }
        return isValid
    }

    const onAcceptSerials = () => {
        setOpenModalAdd(false);
        state.serialesToSave?.forEach((serial, index) => {
            if (!isValidSerial(serial)) {
                state.serialesToSave?.splice(index, 1)
            }
        })
    }

    const rows = [];
    for (let i = 0; i < 20; i++) {
        const serialCharge = state.serialesToSave[i] || { tipo: null, observaciones: '', id: '' };
        rows.push(inputSerials(i, serialCharge));
    }

    const handleCloseSanckbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarInfo({ text: '', type: 'info', open: false });
    };

    const onChangeObservaciones = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setState(prevState => ({ ...prevState, [name]: value }));
    };

    const handleDownload = () => {
        // Definir los datos con el formato adecuado
        const data = [
            ['tipo', 'id', 'observaciones'],
            ['flow', '319102250', ''],
            ['ont', '4875444FC3A', ''],
        ];

        // Crear una hoja de cálculo
        const ws = utils.aoa_to_sheet(data);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, 'Sheet1');

        // Exportar como archivo Excel
        const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'seriales.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    return (
        <Container component="main">
            {saveOk !== -1 ? (
                <div>
                    <Typography variant="h4">Remito creado correctamente!</Typography>
                    {/* <Button variant="contained" onClick={newProduct}>Nuevo</Button> */}
                    <Button href={`/imprimir/${saveOk}`}>
                        Ver remito
                    </Button>
                </div>
            ) : (
                <>
                    {spinner ? (
                        <CircularProgress />
                    ) : (
                        <>
                            <Grid item xs={12} sx={{ minWidth: '500px', marginBottom: '15px' }}>
                                <FormControl sx={{ m: 1, width: '50%' }}>
                                    <InputLabel id="select-label-remito">Tipo Remito</InputLabel>
                                    <Select
                                        label="Tipo"
                                        onChange={onChangeTipoRemito}
                                        value={state.tipoRemito}
                                        className="select__form"
                                        input={<OutlinedInput label="Tipo Remito" />}
                                        sx={{ minWidth: '500px' }}
                                    >
                                        {tipoRemito.map((tipo) => (
                                            <MenuItem key={tipo} value={tipo}>
                                                {tipo}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} sx={{ display: 'inline-flex' }}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        className="date-selector"
                                        label="Fecha"
                                        inputFormat="DD/MM/YYYY"
                                        value={state.date}
                                        onChange={onChangeDate}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <FormControl sx={{ display: 'inline-flex', marginLeft: '10px', minWidth: '300px' }}>
                                <Autocomplete
                                    options={cuadrillas}
                                    id="disable-clearable"
                                    disableClearable
                                    onChange={(e, cuadrilla) => onChangeCuadrillaEntrega(e, cuadrilla)}
                                    getOptionLabel={(option) => option.numero}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    fullWidth
                                    renderInput={(params) => (
                                        <TextField {...params} label="Almacén origen" placeholder="Almacén origen" />
                                    )}
                                />
                            </FormControl>
                            <FormControl sx={{ display: 'inline-flex', marginLeft: '10px', minWidth: '300px' }}>
                                <Autocomplete
                                    options={cuadrillas}
                                    id="disable-clearable"
                                    disableClearable
                                    onChange={(e, cuadrilla) => onChangeCuadrillaDestino(e, cuadrilla)}
                                    getOptionLabel={(option) => option.numero}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    fullWidth
                                    renderInput={(params) => (
                                        <TextField {...params} label="Almacén destino" placeholder="Almacén destino" />
                                    )}
                                />
                            </FormControl>
                            {serialesToSelect && serialesToSelect.length > 0 &&
                                <FormControl sx={{ m: 1, width: '50%' }}>
                                    <Autocomplete
                                        multiple
                                        id="checkboxes-tags-demo"
                                        options={serialesToSelect}
                                        disableCloseOnSelect
                                        onChange={(e, serials) => onChangeSeriales(e, serials)}
                                        getOptionLabel={(option) => option?.id}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option?.id}
                                            </li>
                                        )}
                                        style={{ width: '100%', margin: '10px 0' }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Seriales" placeholder="Seriales" />
                                        )}
                                    />
                                    {state.serialesToSave?.length > 0 &&
                                        state.serialesToSave.map(serial => (
                                            <TextField
                                                id="outlined-number"
                                                label={`Observaciones ${serial?.id}`}
                                                className="text-form__serial"
                                                onChange={(e) => onChangeObservationSeriales(e, serial?.id)}
                                                name="observaciones"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                sx={{ marginBottom: '20px', marginTop: '8px' }}
                                            />
                                        ))

                                    }
                                </FormControl>
                            }
                            {state.cuadrillaEntrega !== '' && (state.cuadrillaEntrega === idCuadrillaNucleo || serialesToSelect?.length === 0) &&
                                <Grid item xs={6} sx={{ marginBottom: '20px' }}>
                                    <div className="custom-file">
                                        <div style={{ marginBottom: '20px' }}>
                                            <Button variant="outlined" onClick={handleDownload} endIcon={<FileDownloadIcon />}>
                                                Planilla de ejemplo
                                            </Button>
                                        </div>
                                        <input type="file" name="file" className="custom-file-input" id="inputGroupFile" required onChange={handleImport}
                                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                                        {state.serialesToSave?.length > 0 &&
                                            <Tooltip title="Ver detalle">
                                                <IconButton
                                                    aria-label="see-more"
                                                    className="action__link"
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        setOpenModalSee(true)
                                                    }}
                                                    role="button"
                                                >
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </div>
                                    {state.serialesToSave?.length > 0 &&
                                        <Dialog open={openModalSee} onClose={handleClose}>
                                            <DialogTitle>Listado de seriales</DialogTitle>
                                            <DialogContent>
                                                <TableContainer component={Paper}>
                                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Tipo</TableCell>
                                                                <TableCell>Número</TableCell>
                                                                <TableCell>Observaciones</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {state.serialesToSave.map((row) => (
                                                                <TableRow
                                                                    key={row?.id}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell component="th" scope="row">
                                                                        {row?.tipo?.toUpperCase()}
                                                                    </TableCell>
                                                                    <TableCell>{row?.id}</TableCell>
                                                                    <TableCell>{row.observaciones || ''}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleClose}>Cerrar</Button>
                                            </DialogActions>
                                        </Dialog>
                                    }
                                </Grid>
                            }
                            {state.cuadrillaEntrega !== '' &&
                                <div>
                                    <Button
                                        variant="outlined"
                                        endIcon={<AddCircleOutlinedIcon />}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            setOpenModalAdd(true)
                                        }}
                                    >
                                        Agregar seriales manualmente
                                    </Button>
                                    <Dialog open={openModalAdd} onClose={handleClose}>
                                        <DialogTitle>Agregar seriales</DialogTitle>
                                        <DialogContent>
                                            {rows}
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={onAcceptSerials}>Aceptar</Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>
                            }
                            <Stack direction="row" spacing={1} sx={{ marginTop: '30px', width: '97%' }}>
                                <TextField
                                    id="outlined-number"
                                    label="Control deco SAGEMCOM"
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    className="text-form__main-materials"
                                    onChange={(e) => onChangeControles(e, 0, 'sagemcom')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{ margin: '0, 10px', flexBasis: '25%' }}
                                />
                                <TextField
                                    id="outlined-number"
                                    label="Control deco SAGEMCOM Reac."
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    className="text-form__main-materials"
                                    onChange={(e) => onChangeControles(e, 1, 'sagemcom reacondicionado')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{ margin: '0, 10px', flexBasis: '25%' }}
                                />
                                <TextField
                                    id="outlined-number"
                                    label="Control deco Android"
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    className="text-form__main-materials"
                                    onChange={(e) => onChangeControles(e, 2, 'android')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{ margin: '0, 10px', flexBasis: '25%' }}
                                />
                                <TextField
                                    id="outlined-number"
                                    label="Control deco Android Reac."
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    className="text-form__main-materials"
                                    onChange={(e) => onChangeControles(e, 3, 'android reacondicionado')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{ margin: '0, 10px', flexBasis: '25%' }}
                                />
                            </Stack>
                            <Box sx={{ display: 'flex', flexDirection: 'initial', marginTop: '40px' }} className="box-container">
                                <FormControl component="fieldset" variant="standard">
                                    <FormGroup sx={{ flexDirection: 'initial' }}>
                                        {state?.materials?.map(material => {
                                            const stock = materialsCuadrillaReceptora.filter(mater => mater.idMaterial === material?.id)[0]?.cantidad || '-'
                                            return (
                                                material.visible &&
                                                <>
                                                    <TextField
                                                        id="outlined-number"
                                                        label={material?.name}
                                                        type="number"
                                                        onWheel={(e) => e.target.blur()}
                                                        key={material?.id}
                                                        placeholder={stock && `Stock: ${stock}`}
                                                        className="text-form__material"
                                                        onChange={(e) => changeInput(e, material?.id, material?.name)}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        sx={{ flexBasis: '24%', margin: '7px 5px 7px 0' }}
                                                    />
                                                    {/* <TextField
                                            id="outlined-number"
                                            label={`Observaciones ${material?.name}`}
                                            type="string"
                                            key={material?.name}
                                            className="text-form__material"
                                            onChange={(e) => changeObservationsMaterials(e, material?.id)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            sx={{ flexBasis: '24%', margin: '7px 5px 7px 0' }}
                                        /> */}
                                                </>
                                            )
                                        })}
                                    </FormGroup>
                                </FormControl>
                            </Box>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    className="default__textfield"
                                    id="observaciones"
                                    label="Observaciones"
                                    value={state.observaciones}
                                    name="observaciones"
                                    sx={{ marginTop: '20px' }}
                                    onChange={onChangeObservaciones}
                                />
                            </Grid>
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className="button__save"
                                // onClick={saveRemito}
                                onClick={() => setOpenPreviewModal(true)}
                                sx={{ margin: '15px 0', width: '50%' }}
                            >
                                Aceptar
                            </Button>
                        </>
                    )}
                    <Dialog
                        open={openPreviewModal}
                        onClose={() => setOpenPreviewModal(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth="md"
                        fullWidth
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Vista previa"}
                        </DialogTitle>
                        <DialogContent>
                            <FacturaRemito dataRemito={formatRemitoToSave(0)} isPreview={true} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenPreviewModal(false)}>Cerrar</Button>
                            <Button onClick={saveRemito}>
                                Guardar
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Snackbar open={snackbarInfo.open} autoHideDuration={2000} onClose={handleCloseSanckbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                        <Alert onClose={handleCloseSanckbar} severity={snackbarInfo.type} sx={{ width: '100%' }}>
                            {snackbarInfo.text}
                        </Alert>
                    </Snackbar>
                </>
            )}
        </Container>
    );
}

export default AddRemito
