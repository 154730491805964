export const cuadrillasFormat = [
    {
        id: 0,
        numero: "CBA CONSTRUIR S.A.",
    },
    {
        id: 1,
        numero: "NUCLEO S.A.",
    },
    {
        id: 2,
        numero: "80CTD754 - GABRIEL ORTIZ",
    },
    {
        id: 3,
        numero: "80CTD393 - NADIA GALEANO",
    },
    {
        id: 4,
        numero: "80CTD340 - ALVARO ROBLEDO",
    },
    {
        id: 5,
        numero: "80CTD656 - RAFAEL AQUINO",
    },
    {
        id: 6,
        numero: "80CTD776 - AGUSTIN SANABRIA",
    },
    {
        id: 7,
        numero: "80CTD688 - CHRISTIAN ALVARENGA",
    },
    {
        id: 8,
        numero: "80CTD783 - RICHARD LOPEZ",
    },
    {
        id: 9,
        numero: "80CTD753 - CHRISTIAN ESQUIVEL",
    },
    {
        id: 10,
        numero: "80CTD658 - FRANCISCO BRITEZ",
    },
    {
        id: 11,
        numero: "80CTD672 - DANNIEL OPORTO",
    },
    {
        id: 12,
        numero: "80CTD673 - CHRISTIAN BAREIRO",
    },
    {
        id: 13,
        numero: "80CTD771 - ANTONIO VALLEJOS",
    },
    {
        id: 14,
        numero: "80CTD686 - ALEXIS BARRIOS",
    },
    {
        id: 15,
        numero: "80CTD850 - DIEGO RAMIREZ",
    },
    {
        id: 16,
        numero: "80CTD394 - JUNIOR SORIA",
    },
    {
        id: 17,
        numero: "80CTD875 - JONATHAN SALINAS",
    },
    {
        id: 18,
        numero: "80CTD399 - LUIS LEDESMA",
    },
    {
        id: 19,
        numero: "80CTD851 - FRANCISCO JACQUET",
    },
    {
        id: 20,
        numero: "80CTD834 - ANDDSERSON LOPEZ",
    },
    {
        id: 21,
        numero: "80CTD782 - LUIS BOGADO",
    },
    {
        id: 22,
        numero: "80CTD885 - KEVIN MARTINEZ",
    },
    {
        id: 23,
        numero: "80CTD481 - JOSE PAIVA",
    },
    {
        id: 24,
        numero: "80CTD391 - HERME GALVAN",
    },
    {
        id: 25,
        numero: "80CTD794 - MAURICIO DIAZ",
    },
    {
        id: 26,
        numero: "80CTD395 - PABLO MENDEZ",
    },
    {
        id: 27,
        numero: "80CTD397 - CARLOS TORRES",
    },
    {
        id: 28,
        numero: "80CTD396 - ANIBAL ROJAS",
    },
    {
        id: 29,
        numero: "80CTD889 - JOSE ACOSTA",
    },
    {
        id: 30,
        numero: "80CTD883 - CESAR PEREIRA",
    },
    {
        id: 31,
        numero: "80CTD392 - JORGE MANZANO",
    },
    {
        id: 32,
        numero: "80CTD864 - DANIEL BAREIRO",
    },
    {
        id: 33,
        numero: "80CTD852 -  JUAN ALEGRE",
    },
    {
        id: 34,
        numero: "80CTD660 - OSVALDO MARTINEZ",
    },
    {
        id: 99,
        numero: "123456"
    },
]