import React, { useState, useEffect } from "react";
import { database } from "../../firebase";
import { ref, onValue } from "firebase/database";
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { StyledTableCell, StyledTableRow } from '../../utils/styled'
import { getPricesOnce } from "../../services/priceService";

function ListPrices() {
    const [prices, setPrices] = useState([])
    const [precioFilter, setPrecioFilter] = useState([])
    const [searchTitle, setSearchTitle] = useState('')

    useEffect(() => {
        getPricesOnce(setPrices)
    }, []);


    const displayTable = searchTitle !== "" ? precioFilter : prices;

    return (
        <Container component="main" maxWidth="xl">
            <h4>Listado de precios</h4>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Id</StyledTableCell>
                            <StyledTableCell>Descripción</StyledTableCell>
                            <StyledTableCell>Unidad de medida</StyledTableCell>
                            <StyledTableCell>Hogar</StyledTableCell>
                            <StyledTableCell>Feriado/Domingo (Hogar)</StyledTableCell>
                            <StyledTableCell>Acciones</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {prices &&
                            displayTable.map((price, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell>{price.id}</StyledTableCell>
                                    <StyledTableCell>{price.descripcion}</StyledTableCell>
                                    <StyledTableCell>{price.unidad_medida}</StyledTableCell>
                                    <StyledTableCell>$ {price.hogar}</StyledTableCell>
                                    <StyledTableCell>$ {price.feriado_domingo_hogar}</StyledTableCell>

                                    <StyledTableCell className="column-actions" sx={{ display: 'inline-flex' }}>
                                        <IconButton
                                            aria-label="delete"
                                            className="action__link"
                                            href={`/prices/${price.id}`}
                                            role="button"
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            aria-label="delete"
                                            type="button"
                                            className="action__button"
                                        // onClick={() =>
                                        //     alert('Eliminar', 'Estás seguro???', [
                                        //         { text: 'Cancelar' },
                                        //         {
                                        //             text: 'Ok',
                                        //             onPress: () => this.deleteReserva(producto.key),
                                        //         },
                                        //     ])
                                        // }
                                        >
                                            <DeleteIcon sx={{ color: 'red' }} />
                                        </IconButton>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
}

export default ListPrices;
