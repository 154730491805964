import React from 'react';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { tipoDeco } from "../../utils/default";

export default function InputSerials({ index, serialCharge, onChangeTipoSerial, onChangeValues, observations }) {
  return (
    <>
        <FormControl key={index} sx={{ m: 1, width: '20%' }}>
            <InputLabel id="select-label-remito">Tipo</InputLabel>
            <Select
                label="Tipo"
                onChange={(e) => onChangeTipoSerial(e, index)}
                value={serialCharge !== undefined ? serialCharge?.tipo : ''}
                className="select__form"
                input={<OutlinedInput label="Tipo Remito" />}
            >
                {tipoDeco.map((tipo) => (
                    <MenuItem key={tipo} value={tipo}>
                        {tipo.toUpperCase()}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
        <TextField
            id="outlined-number"
            label="Serial"
            className="text-form__serial"
            onChange={(e) => onChangeValues(e, index)}
            name="id"
            InputLabelProps={{
                shrink: true,
            }}
            sx={{ marginBottom: '20px', marginRight: '10px', marginTop: '8px' }}
            defaultValue={serialCharge?.id || ''}
        />
        <TextField
            id="outlined-number"
            label="Observaciones"
            className="text-form__serial"
            onChange={(e) => onChangeValues(e, index)}
            name="observaciones"
            InputLabelProps={{
                shrink: true,
            }}
            sx={{ marginBottom: '20px', marginRight: '10px', marginTop: '8px' }}
            defaultValue={serialCharge?.observaciones || ''}
            disabled={!observations}
        />
    </>
  );
}