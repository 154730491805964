import React, { useState, useEffect } from "react";
import { database } from "../../firebase";
import { ref, onValue, remove, query, orderByChild, startAfter, limitToLast, limitToFirst, equalTo, endBefore } from "firebase/database";
import { getFirestore, collection, query as queryFirestore, where, orderBy, limit, startAfter as startAfterF, getDocs, getCountFromServer, doc, deleteDoc } from 'firebase/firestore';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import SnackbarWrapper from "../../components/Snackbar/SnackBarWrapper";
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import MuiAlert from '@mui/material/Alert';
import { StyledTableCell, StyledTableRow } from '../../utils/styled'
import PrintIcon from '@mui/icons-material/Print';
import { filesPage } from '../../utils/default'
import { CircularProgress, Pagination } from "@mui/material";
import ModalFilters from "../../components/ModalFilters/ModalFilters";

const firestore = getFirestore();

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ListRemitos() {
    const [remitos, setRemitos] = useState([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [remitoToDelete, setRemitoToDelete] = useState(-1);
    const [snackbarInfo, setSnackbarInfo] = useState({ text: '', type: '', open: false });
    const [searchTitle, setSearchTitle] = useState('');
    const [remitoFilter, setRemitoFilter] = useState([]);
    const [pagePagination, setPagePagination] = useState(1);
    const [lastVisible, setLastVisible] = useState(null); // Para paginación
    const [pageQuantity, setPageQuantity] = useState(1);
    const [numberPage, setNumberPage] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [openSearchModal, setOpenSearchModal] = useState(false);

    const remitosCollection = collection(firestore, 'remitos');

    // Configuración del número de registros por página
    const recordsPerPage = 100;

    // Obtener el número de página del query param
    const params = new URLSearchParams(window.location.search);
    const pageNumber = parseInt(params.get('page')) || 1;
    // setNumberPage(pageNumber)

    const calculateStartId = (page) => (page - 1) * recordsPerPage;

    const getLastId = async () => {
        try {
            // Referencia a la colección "cuadrillas" o la colección que estés usando
            const collectionRef = collection(firestore, 'remitos');

            // Consulta para obtener el documento con el `id` más alto
            const lastIdQuery = query(collectionRef, orderBy('id', 'desc'), limit(1));
            const snapshot = await getDocs(lastIdQuery);

            if (!snapshot.empty) {
                // Obtiene el campo `id` del documento con el ID más alto
                const lastDoc = snapshot.docs[0].data();
                const totalCount = lastDoc.id; // Asumiendo que el `id` representa el total de registros
                return totalCount;
            } else {
                return 0; // No hay documentos en la colección
            }
        } catch (error) {
            console.error("Error al obtener el último ID:", error);
            return 0;
        }
    };

    const fetchTotalCount = async () => {
        // Preparamos la consulta base
        let countQuery = query(remitosCollection);

        const cuadrillaEntrega = params.get('cuadrillaEntrega');
        const cuadrillaReceptora = params.get('cuadrillaReceptora');
        const idRemito = params.get('idRemito');

        // Agregar condiciones de filtro
        if (cuadrillaEntrega) {
            countQuery = query(countQuery, where('idCuadrillaEntrega', '==', parseInt(cuadrillaEntrega, 10)));
        }
        if (cuadrillaReceptora) {
            countQuery = query(countQuery, where('idCuadrillaReceptora', '==', parseInt(cuadrillaReceptora, 10)));
        }
        if (idRemito) {
            countQuery = query(countQuery, where('id', '==', parseInt(idRemito, 10)));
        }

        // Obtener la cuenta total de documentos
        // const snapshot = await getCountFromServer(countQuery);
        const totalCount = await getLastId();

        // Calcular el número total de páginas
        const pages = Math.ceil(totalCount / recordsPerPage);
        setPageQuantity(pages);
    };

    const fetchRemitos = async (page) => {
        // Calcular el ID de inicio
        const startId = calculateStartId(page);

        // Preparamos la consulta base
        let remitosQuery = query(
            remitosCollection,
            orderBy('id', 'asc'), // Orden ascendente por ID
            where('id', '>=', startId), // Filtrar desde el ID de inicio calculado
            limit(recordsPerPage) // Limitar al número de registros por página
        );

        // Aplicar filtros
        const cuadrillaEntrega = params.get('cuadrillaEntrega');
        const cuadrillaReceptora = params.get('cuadrillaReceptora');
        const idRemito = params.get('idRemito');

        // Agregar condiciones de filtro
        if (cuadrillaEntrega) {
            remitosQuery = query(remitosCollection, orderBy('idCuadrillaEntrega', 'asc'), where('idCuadrillaEntrega', '==', parseInt(cuadrillaEntrega, 10)));
        }
        if (cuadrillaReceptora) {
            remitosQuery = query(remitosCollection, orderBy('idCuadrillaReceptora', 'asc'), where('idCuadrillaReceptora', '==', parseInt(cuadrillaReceptora, 10)));
        }
        if (idRemito) {
            remitosQuery = query(remitosQuery, where('id', '==', parseInt(idRemito, 10)));
        }

        // Ejecutar la consulta
        const querySnapshot = await getDocs(remitosQuery);

        if (!querySnapshot.empty) {
            const data = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setRemitos(data);
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
            setIsSearch(true); // Se está realizando una búsqueda
        } else {
            setRemitos([]);
            setIsSearch(false); // No se encontraron resultados
        }
    };

    useEffect(() => {
        if (!pageNumber & params.size === 0) {
            window.location.href = `?page=${1}`;
        }
        fetchTotalCount()
        fetchRemitos(pageNumber);

    }, []);

    const oldGets = () => {
        const lastId = query(ref(database, 'remitos'), orderByChild('id'), limitToLast(1));
        onValue(lastId, (snapshot) => {
            const key = snapshot.val() ? Object.keys(snapshot.val()) : 0;
            const pageQ = Math.ceil(parseInt(key, 10) / filesPage)
            setPageQuantity(pageQ)
        }, {
            onlyOnce: true
        });

        const params = new URLSearchParams(window.location.search);

        const pageNumber = params.get('page');
        setNumberPage(pageNumber)
        const cuadrillaEntrega = params.get('cuadrillaEntrega');
        const cuadrillaReceptora = params.get('cuadrillaReceptora');
        const idRemito = params.get('idRemito');

        if (cuadrillaEntrega) {
            const searchQuery = query(ref(database, 'remitos'), orderByChild('idCuadrillaEntrega'), equalTo(parseInt(cuadrillaEntrega, 10)));
            onValue(searchQuery, (snapshot) => {
                setIsSearch(true)
                if (snapshot.val()) {
                    setRemitos(Object.values(snapshot.val()))
                } else {
                    setRemitos([])
                };
            }, {
                onlyOnce: true
            });
        }
        if (cuadrillaReceptora) {
            const searchQuery = query(ref(database, 'remitos'), orderByChild('idCuadrillaReceptora'), equalTo(parseInt(cuadrillaReceptora, 10)));
            onValue(searchQuery, (snapshot) => {
                setIsSearch(true)
                if (snapshot.val()) {
                    setRemitos(Object.values(snapshot.val()))
                } else {
                    setRemitos([])
                };
            }, {
                onlyOnce: true
            });
        }
        if (idRemito) {
            const searchQuery = query(ref(database, 'remitos'), orderByChild('id'), equalTo(parseInt(idRemito, 10)));
            onValue(searchQuery, (snapshot) => {
                setIsSearch(true)
                if (snapshot.val()) {
                    setRemitos(Object.values(snapshot.val()))
                } else {
                    setRemitos([])
                };
            }, {
                onlyOnce: true
            });
        }

        setNumberPage(pageNumber)
        setPagePagination(pageNumber)

        if (!pageNumber & params.size === 0) {
            window.location.href = `?page=${1}`;
        }

        const startFileId = (filesPage * (pageNumber - 1) - 1)
        const paginationQuery =
            query(ref(database, 'remitos'),
                orderByChild('id'),
                startAfter(startFileId),
                limitToFirst(filesPage)
            );
        if (!cuadrillaEntrega && !cuadrillaReceptora && !idRemito) {
            onValue(paginationQuery, (snapshot) => {
                setRemitos(Object.values(snapshot.val()))
            }, {
                onlyOnce: true
            });
        }
    };

    const onDataChange = (items) => {
        setRemitos(items);
    }

    const handleClose = () => {
        setOpenDeleteModal(false)
    }

    // const deleteRemito = () => {
    //     remove(ref(database, 'remitos/' + remitoToDelete))
    //         .then(() => {
    //             setSnackbarInfo({ text: 'Eliminado correctamente!', type: 'success', open: true })
    //             setOpenDeleteModal(false);
    //             setTimeout(() => {
    //                 window.location.reload();
    //             }, 2500);
    //         })
    //         .catch((error) => {
    //             setOpenDeleteModal(false)
    //             setSnackbarInfo({ text: 'Ocurrió un error', type: 'error', open: true })
    //         });
    // }

    const deleteRemito = async () => {
        try {
            // Referencia al documento a eliminar en Firestore
            const docRef = doc(firestore, 'remitos', String(remitoToDelete));

            // Eliminar el documento
            await deleteDoc(docRef);

            // Mostrar mensaje de éxito
            setSnackbarInfo({ text: 'Eliminado correctamente!', type: 'success', open: true });
            setOpenDeleteModal(false);

            // Recargar la página después de 2.5 segundos (opcional)
            setTimeout(() => {
                window.location.reload();
            }, 2500);
        } catch (error) {
            // Manejar el error
            console.log('error', error);

            setOpenDeleteModal(false);
            setSnackbarInfo({ text: 'Ocurrió un error', type: 'error', open: true });
        }
    };

    const handleCloseSanckbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        // setSnackbarInfo({ text: '', type: '', open: false });
    };

    const searchFilter = (e) => {
        const value = e.target.value;
        setTimeout(() => {
            if (value) {
                const filter = remitos.filter(
                    (product) =>
                        product.cuadrillaEntrega.toLowerCase().match(value.toLowerCase()) ||
                        product.cuadrillaReceptora.toLowerCase().match(value.toLowerCase()) ||
                        product.id.toString().toLowerCase().match(value.toLowerCase())
                );
                setSearchTitle(value);
                setRemitoFilter(filter);
            } else {
                setSearchTitle('');
            }
        }, 500);
    }

    const onChangePage = (number) => {
        window.location.href = `?page=${number}`;
    }

    const clearFilters = () => {
        const params = new URLSearchParams(window.location.search);
        const pageNumber = params.get('page') || 1;
        window.location.href = `?page=${pageNumber}`
    }

    const displayTable = searchTitle !== "" ? remitoFilter : remitos;

    return (
        <Container component="main" maxWidth="xl">
            <h4>Listado de remitos</h4>
            <div className="filters-container">
                <Button
                    variant="contained"
                    endIcon={<SearchIcon />}
                    sx={{ marginBottom: '20px' }}
                    onClick={() => setOpenSearchModal(true)}
                >
                    Nueva Búsqueda
                </Button>
                <Button
                    variant="outlined"
                    // endIcon={<SearchIcon />}
                    sx={{ marginLeft: '20px', marginBottom: '20px' }}
                    onClick={() => clearFilters()}
                >
                    Limpiar Filtros
                </Button>
                <Button variant="contained" href="/remito" sx={{ marginBottom: '20px', marginLeft: 'auto' }}>Nuevo remito</Button>
            </div>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Número</StyledTableCell>
                            <StyledTableCell>Tipo</StyledTableCell>
                            <StyledTableCell>Almacén origen</StyledTableCell>
                            <StyledTableCell>Almacén destino</StyledTableCell>
                            <StyledTableCell>Fecha</StyledTableCell>
                            <StyledTableCell>Acciones</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayTable?.length > 0 ? (
                            displayTable.map((remito, index) => (
                                <StyledTableRow
                                    key={index}
                                    sx={remitos?.deleted && { display: 'none' }}
                                >
                                    <StyledTableCell>{remito.id}</StyledTableCell>
                                    <StyledTableCell>{remito.tipoRemito}</StyledTableCell>
                                    <StyledTableCell>{remito.cuadrillaEntrega}</StyledTableCell>
                                    <StyledTableCell>{remito.cuadrillaReceptora}</StyledTableCell>
                                    <StyledTableCell>{remito.fecha}</StyledTableCell>
                                    {/* <StyledTableCell>{remito?.seriales && remito.seriales.map(serial => (
                                        <div>
                                            <span>{serial.tipo.toUpperCase()} : </span>
                                            <span> {serial.id}</span>
                                        </div>
                                    ))
                                    }
                                    </StyledTableCell> */}

                                    <StyledTableCell className="column-actions">
                                        <IconButton
                                            aria-label="print"
                                            className="action__link"
                                            href={`/imprimir/${remito.id}`}
                                            role="button"
                                        >
                                            <PrintIcon />
                                        </IconButton>
                                        <IconButton
                                            aria-label="edit"
                                            className="action__link"
                                            // href={`/remito/${remito.id}`}
                                            role="button"
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            aria-label="delete"
                                            type="button"
                                            className="action__button"
                                            onClick={() => {
                                                setOpenDeleteModal(true);
                                                setRemitoToDelete(remito.id)
                                            }}
                                        >
                                            <DeleteIcon sx={{ color: 'red' }} />
                                        </IconButton>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                        ) : (
                            <StyledTableRow>
                                <StyledTableCell colSpan={13} align="center">
                                    {isSearch ? (
                                        <Typography variant="h5">
                                            No se encontraron elementos
                                        </Typography>
                                    ) : (
                                        <CircularProgress />
                                    )}
                                </StyledTableCell>
                            </StyledTableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {displayTable?.length > 0 && pageNumber &&
                <Pagination
                    count={pageQuantity}
                    page={parseInt(pageNumber, 10)}
                    className="list-pagination"
                    onChange={(e, number) => onChangePage(number)}
                    size="large"
                    sx={{ margin: '15px 0', justifyContent: 'center' }}
                />
            }
            {openSearchModal &&
                <ModalFilters
                    open={openSearchModal}
                    onCloseModal={() => setOpenSearchModal(false)}
                    items={['cuadrillaEntrega', 'idRemito', 'cuadrillaReceptora']}
                />
            }
            <Dialog
                open={openDeleteModal}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Desea eliminar este remito?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Los cambios serán permanentes.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cerrar</Button>
                    <Button onClick={deleteRemito} autoFocus>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={snackbarInfo.open} autoHideDuration={2000} onClose={handleCloseSanckbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleCloseSanckbar} severity={snackbarInfo.type} sx={{ width: '100%' }}>
                    {snackbarInfo.text}
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default ListRemitos;
