import React, { useState, useEffect } from "react";
import { equalTo, onValue, ref, query, orderByChild, limitToLast } from "firebase/database";
import { database } from "../../firebase";
import { v4 as uuidv4 } from 'uuid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ProductosDataService from "../../services/ordenes.service";
import moment from "moment";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { tipoCliente, horasTrabajo, semana, tipoOt, ciudades, departamentos } from "../../utils/default";
import { Autocomplete } from "@mui/material";
import { getCuadrillasOnce } from '../../services/cuadrillasService';

const AlertSnack = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AddOrdenes({ nextStep }) {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    const [cuadrillas, setCuadrillas] = useState([])
    const [state, setState] = useState({
        lastId: -1,
        idCuadrilla: -1,
        codCuadrilla: "",
        cuadrilla: '',
        item: "",
        nroFormulario: "",
        nroOt: "",
        departamento: 'CENTRAL',
        contrato: "",
        cliente: "",
        recibido: "",
        direccion: "",
        ciudad: "",
        latitud: "",
        longitud: "",
        nap: "",
        tipoCliente: 'HOME',
        horasTrabajo: 'Normal',
        tipoDia: 'Semana',
        tipoOt: 'Instalacion',
        date: null,
        submitted: false,
    });
    const [spinner, setSpinner] = useState(false)
    const [snackbarInfo, setSnackbarInfo] = useState({ text: '', type: '', open: false });
    const [alertValidationDate, setAlertValidationDate] = useState('')
    const [alertValidationFormulario, setAlertValidationFormulario] = useState('')
    const [alertValidationNroOt, setAlertValidationNroOt] = useState('')
    const [alertValidationClient, setAlertValidationClient] = useState('')
    const [alertValidationDireccion, setAlertValidationDireccion] = useState('')
    const [alertValidationContrato, setAlertValidationContrato] = useState('')
    const [isValidating, setIsValidating] = useState(false)
    const [isOther, setIsOther] = useState(false)

    useEffect(() => {
        // const topUserPostsRef = query(ref(database, 'ordenes'), orderByChild('id'), limitToLast(1));
        // onValue(topUserPostsRef, (snapshot) => {
        //     const key = snapshot.val() ? Object.keys(snapshot.val()) : 0;
        //     setState(prevState => ({ ...prevState, lastId: parseInt(key, 10) }));
        // }, {
        //     onlyOnce: true
        // });
        // onValue(ref(database, '/cuadrillas/'), (snapshot) => {
        //     setCuadrillas(Object.values(snapshot.val()))
        // }, {
        //     onlyOnce: true
        // });

        getCuadrillasOnce(setCuadrillas)
    }, []);

    const onChangeFormulario = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (e.target.value.toString().length <= 9) {
            setState(prevState => ({ ...prevState, [name]: value }));
        }
    }

    const onChangeNroOt = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (e.target.value.toString().length <= 9) {
            setState(prevState => ({ ...prevState, [name]: value }));
        }
    }

    const onChangeContrato = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const longitud = value.toString().length;
        if (longitud <= 8) {
            setState(prevState => ({ ...prevState, [name]: value }));
        }
        setTimeout(() => {
            if (longitud < 7) {
                setAlertValidationContrato(`El numero ingresado es de ${longitud} dígitos y lo común es que sea de 7 u 8`);
            } else if (longitud > 7) {
                setAlertValidationContrato('');
            }
        }, 1000)
    }

    const onChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setState(prevState => ({ ...prevState, [name]: value }));
    };

    const onChangeDate = (value) => {
        const dateFrom = moment(new Date()).subtract(30, 'days').format("YYYYMMDD");
        const d = new Date(value);
        let day = d.getDay();
        const isBeforeDate = moment(d).isBefore(moment(new Date()));
        const isBeforeMonth = moment(d).isBefore(moment(dateFrom));
        if (isBeforeDate && !isBeforeMonth) {
            setState(prevState => ({ ...prevState, date: value }));
            setAlertValidationDate('')
        } else {
            setAlertValidationDate('La fecha debe ser menor o igual a la actual y no tener un período mayor a 30 días de la fecha actual')
        }
        if (day === 0) {
            setState(prevState => ({ ...prevState, tipoDia: 'Domingo/Feriado' }));
        } else {
            setState(prevState => ({ ...prevState, tipoDia: 'Semana' }));
        }
    };

    const onChangeSelect = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        setState(prevState => ({ ...prevState, [name]: value }));
    }

    const onChangeSelectCiudad = (event, value) => {
        if (value !== 'OTRO') {
            setState(prevState => ({ ...prevState, ciudad: value }));
            setIsOther(false)
        } else {
            setIsOther(true)
        }
    }

    const onChangeSelectDepto = (event, value) => {
        setState(prevState => ({ ...prevState, departamento: value }));
    }

    const onChangeSelectCuadrilla = (event, cuadrilla) => {
        const nroCuadrilla = cuadrilla.numero;
        const idCuadrilla = cuadrilla.id;
        const codCuadrilla = cuadrilla?.codigo
        setState(prevState => ({ ...prevState, cuadrilla: nroCuadrilla, idCuadrilla, codCuadrilla }));
    }

    const validateData = (type, number) => {
        const searchQuery = query(ref(database, 'ordenes'), orderByChild(type), equalTo(number));
        onValue(searchQuery, (snapshot) => {
            if (snapshot.val()) {
                setSnackbarInfo({ text: `El ${type} ya existe para otra OT`, type: 'error', open: true })
                setIsValidating(false)
            } else {
                setSnackbarInfo({ text: `No existe ningun ${type} con este número`, type: 'success', open: true })
                setIsValidating(false)
            }
        }, {
            onlyOnce: true
        });
        setIsValidating(true)
    }

    const validations = () => {
        let countErrors = 0
        if (state.nroFormulario.toString().length < 7) {
            setAlertValidationFormulario('El nro de formulario debe tener mínimo 7 digitos')
            countErrors += 1;
        } else {
            setAlertValidationFormulario('')
        }
        if (state.nroOt.toString().length < 7) {
            setAlertValidationNroOt('El nro de OT debe tener mínimo 7 digitos')
            countErrors += 1;
        } else {
            setAlertValidationNroOt('')
        }
        if (typeof state.cliente === 'string' && !isNaN(state.cliente)) {
            setAlertValidationClient('El cliente no puede ser numérico')
            countErrors += 1;
        } else {
            setAlertValidationClient('')
        }
        if (state.direccion !== '' && typeof state.direccion === 'string' && !isNaN(state.direccion)) {
            setAlertValidationDireccion('La dirección no puede ser solo numérica')
            countErrors += 1;
        } else {
            setAlertValidationDireccion('')
        }
        return countErrors > 0 ? false : true;
    }

    function generateNumericUniqueId() {
        const randomId = Math.floor(Math.random() * 9000000000) + 1000000000;
        return String(randomId);
    }


    const saveData = (lastId) => {
        let formularioExists = false;
        const searchQuery = query(ref(database, 'ordenes'), orderByChild('nroFormulario'), equalTo(state.nroFormulario));
        onValue(searchQuery, (snapshot) => {
            if (snapshot.val()) {
                formularioExists = true
            }
        }, {
            onlyOnce: true
        });

        setTimeout(() => {
            let data = {
                idCuadrilla: state.idCuadrilla,
                codCuadrilla: state.codCuadrilla,
                cuadrilla: state.cuadrilla,
                item: state.item,
                nroFormulario: state.nroFormulario,
                nroOt: state.nroOt,
                contrato: state.contrato,
                cliente: state.cliente,
                recibido: state.recibido,
                direccion: state.direccion,
                ciudad: state.ciudad,
                departamento: state.departamento,
                latitud: state.latitud,
                longitud: state.longitud,
                nap: state.nap,
                tipoCliente: state.tipoCliente,
                tipoOt: state.tipoOt,
                horasTrabajo: state.horasTrabajo,
                date: moment(state.date).format("YYYYMMDD"),
                tipoDia: state.tipoDia,
                user: currentUser?.email,
            };

            if (!formularioExists) {
                nextStep(data)
                // ProductosDataService.create(data, uniqueId)
                //     .then(() => {
                //         const dataCuadrilla = {
                //             id: state.idCuadrilla,
                //             numero: state.cuadrilla,
                //         }
                //         sessionStorage.setItem('cuadrilla', JSON.stringify(dataCuadrilla))
                //         if (data.tipoOt === 'Mudanza externa') {
                //             sessionStorage.setItem('mudanza', true)
                //         }
                //         window.location.href = `/services/${data.id}`
                //     })
                //     .catch((e) => {
                //         setSnackbarInfo({ text: 'Ocurrió un error al guardar la OT, intente nuevamente', type: 'error', open: true })
                //     });
            } else {
                setAlertValidationFormulario('El nro de formulario ya existe para otra OT')
                setSnackbarInfo({ text: 'El nro de formulario ya existe para otra OT', type: 'error', open: true })
                setSpinner(false)
            }
        }, 1500);
    }

    const saveOrder = () => {
        let lastId = -1;
        const validateResult = validations();

        if (validateResult) {
            setSpinner(true);
            const topUserPostsRef = query(ref(database, 'ordenes'), orderByChild('id'), limitToLast(1));
            onValue(topUserPostsRef, (snapshot) => {
                const key = snapshot.val() ? Object.keys(snapshot.val()) : -1;
                lastId = parseInt(key, 10);
                saveData(lastId)
            }, {
                onlyOnce: true
            });
        }
    }

    const newProduct = () => {
        setState({
            cuadrilla: '',
            item: "",
            nroFormulario: "",
            nroOt: "",
            contrato: "",
            cliente: "",
            recibido: "",
            direccion: "",
            ciudad: '',
            latitud: "",
            longitud: "",
            nap: "",
            tipoCliente: "",
            tipoOt: "",
            horasTrabajo: "",
            tipoDia: '',
            date: moment(new Date().getTime()),
            lastId: state.lastId,

            submitted: false,
        });
    }

    const handleCloseSanckbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarInfo({ text: '', type: 'info', open: false });
    };

    return (
        <Container component="main" maxWidth="xs">
            {spinner ? (
                <CircularProgress />
            ) : (
                <div className="form-container">
                    <Typography component="h1" variant="h5">
                        Nueva OT
                    </Typography>
                    <div className="order-container">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl sx={{ width: '100%' }}>
                                    <Autocomplete
                                        options={cuadrillas}
                                        id="disable-clearable"
                                        disableClearable
                                        onChange={(e, cuadrilla) => onChangeSelectCuadrilla(e, cuadrilla)}
                                        getOptionLabel={(option) => option.numero}
                                        fullWidth
                                        renderInput={(params) => (
                                            <TextField {...params} label="Cuadrilla" placeholder="Cuadrilla" />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    className="default__textfield"
                                    id="nroFormulario"
                                    label="Nro Formulario"
                                    value={state.nroFormulario}
                                    name="nroFormulario"
                                    onChange={onChangeFormulario}
                                    sx={{ width: '290px' }}
                                />
                                <LoadingButton
                                    loading={isValidating}
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    sx={{ margin: '8px 0 0 10px' }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        validateData('nroFormulario', state.nroFormulario);
                                    }}
                                    disabled={state.nroFormulario === ''}
                                >
                                    Validar
                                </LoadingButton>
                                {alertValidationFormulario !== '' && <MuiAlert severity="error">{alertValidationFormulario}</MuiAlert>}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    className="default__textfield"
                                    id="nroOt"
                                    label="Nro. OT"
                                    value={state.nroOt}
                                    name="nroOt"
                                    onChange={onChangeNroOt}
                                />
                                {alertValidationNroOt !== '' && <MuiAlert severity="error">{alertValidationNroOt}</MuiAlert>}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    className="default__textfield"
                                    id="contrato"
                                    label="Contrato"
                                    value={state.contrato}
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    name="contrato"
                                    onChange={onChangeContrato}
                                    sx={{ width: '290px' }}
                                />
                                <LoadingButton
                                    loading={isValidating}
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    sx={{ margin: '8px 0 0 10px' }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        validateData('contrato', state.contrato);
                                    }}
                                    disabled={state.contrato === ''}
                                >
                                    Validar
                                </LoadingButton>
                                {alertValidationContrato !== '' && <MuiAlert severity="warning">{alertValidationContrato}</MuiAlert>}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    className="default__textfield"
                                    id="cliente"
                                    label="Cliente"
                                    value={state.cliente}
                                    name="cliente"
                                    onChange={onChangeValues}
                                />
                                {alertValidationClient !== '' && <MuiAlert severity="error">{alertValidationClient}</MuiAlert>}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    className="default__textfield"
                                    id="recibido"
                                    label="Persona que recibió"
                                    value={state.recibido}
                                    name="recibido"
                                    onChange={onChangeValues}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    className="default__textfield"
                                    id="direccion"
                                    label="Dirección"
                                    value={state.direccion}
                                    name="direccion"
                                    onChange={onChangeValues}
                                />
                                {alertValidationDireccion !== '' && <MuiAlert severity="error">{alertValidationDireccion}</MuiAlert>}
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl sx={{ width: '100%' }}>
                                    <Autocomplete
                                        options={ciudades}
                                        id="disable-clearable"
                                        disableClearable
                                        onChange={onChangeSelectCiudad}
                                        getOptionLabel={(option) => option}
                                        fullWidth
                                        renderInput={(params) => (
                                            <TextField {...params} label="Ciudad" placeholder="Ciudad" name="ciudad" />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            {isOther &&
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        className="default__textfield"
                                        id="ciudad"
                                        label="Ciudad"
                                        placeholder="Ingrese ciudad"
                                        value={state.ciudad}
                                        name="ciudad"
                                        onChange={onChangeValues}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <FormControl sx={{ width: '100%' }}>
                                    <Autocomplete
                                        options={departamentos}
                                        id="disable-clearable"
                                        disableClearable
                                        onChange={onChangeSelectDepto}
                                        value={state.departamento}
                                        getOptionLabel={(option) => option}
                                        fullWidth
                                        renderInput={(params) => (
                                            <TextField {...params} label="Departamento" placeholder="Departamento" />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    className="default__textfield"
                                    id="latitud"
                                    label="latitud"
                                    value={state.latitud}
                                    name="latitud"
                                    onChange={onChangeValues}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    className="default__textfield"
                                    id="longitud"
                                    label="longitud"
                                    value={state.longitud}
                                    name="longitud"
                                    onChange={onChangeValues}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    className="default__textfield"
                                    id="nap"
                                    label="NAP"
                                    value={state.nap}
                                    name="nap"
                                    onChange={onChangeValues}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel>Tipo Cliente</InputLabel>
                                    <Select
                                        onChange={onChangeSelect}
                                        value={state.tipoCliente}
                                        name="tipoCliente"
                                        className="select__form"
                                        input={<OutlinedInput label="Tipo Cliente" />}
                                        fullWidth
                                    >
                                        {tipoCliente.map((tipo) => (
                                            <MenuItem key={tipo} value={tipo}>
                                                {tipo}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel>Hora de trabajo</InputLabel>
                                    <Select
                                        onChange={onChangeSelect}
                                        value={state.horasTrabajo}
                                        name="horasTrabajo"
                                        className="select__form"
                                        input={<OutlinedInput label="Hora de trabajo" />}
                                        fullWidth
                                    >
                                        {horasTrabajo.map((horas) => (
                                            <MenuItem key={horas} value={horas}>
                                                {horas}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        className="date-selector"
                                        label="Fecha Asistida"
                                        inputFormat="DD/MM/YYYY"
                                        value={state.date}
                                        onChange={onChangeDate}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                                {alertValidationDate !== '' && <MuiAlert severity="error">{alertValidationDate}</MuiAlert>}
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel>Tipo día semana</InputLabel>
                                    <Select
                                        onChange={onChangeSelect}
                                        value={state.tipoDia}
                                        name="tipoDia"
                                        input={<OutlinedInput label="Tipo día semana" />}
                                        className="select__form"
                                        fullWidth
                                    >
                                        {semana.map((semana) => (
                                            <MenuItem key={semana} value={semana}>
                                                {semana}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel>Tipo OT</InputLabel>
                                    <Select
                                        onChange={onChangeSelect}
                                        name="tipoOt"
                                        value={state.tipoOt}
                                        input={<OutlinedInput label="Tipo OT" />}
                                        className="select__form"
                                        fullWidth
                                    >
                                        {tipoOt.map((tipo) => (
                                            <MenuItem key={tipo} value={tipo}>
                                                {tipo}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className="button__save"
                            onClick={saveOrder}
                            disabled={
                                state.cuadrilla === '' ||
                                state.nroFormulario === '' ||
                                state.nroOt === '' ||
                                state.contrato === '' ||
                                state.cliente === '' ||
                                state.date === null ||
                                state.ciudad === ''
                            }
                        >
                            Siguiente
                        </Button>
                    </div>
                </div>
            )}
            <Snackbar open={snackbarInfo.open} autoHideDuration={2000} onClose={handleCloseSanckbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <AlertSnack onClose={handleCloseSanckbar} severity={snackbarInfo.type} sx={{ width: '100%' }}>
                    {snackbarInfo.text}
                </AlertSnack>
            </Snackbar>
        </Container>
    );
}

export default AddOrdenes;
