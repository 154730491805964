import { getFirestore, collection, query, where, getDocs, orderBy, doc, updateDoc } from "firebase/firestore";
const firestore = getFirestore();

export const getPricesOnce = async (onDataChange) => {
    try {
        const pricesCollection = collection(firestore, 'precios');
        const queryPrices = query(pricesCollection, orderBy('id', 'asc'));
        const snapshot = await getDocs(queryPrices);
        const data = snapshot.docs.map(doc => doc.data());
        onDataChange(data);
    } catch (error) {
        console.error("Error al obtener los datos de precios:", error);
    }
};