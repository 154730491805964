import React, { Component } from "react";
import CryptoJS from "crypto-js";
// import { Toast } from "antd-mobile";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/Lock';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LoginService from "../../services/users.service";
import { passKey } from "../../utils/default";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userName: "",
      password: "",
      email: "",
      rol: "personal",
      open: false,
    };
    this.onSaveUser = this.onSaveUser.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  onChangeValues(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }

  handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ open: false })
  };

  onSaveUser() {
    var ciphertext = CryptoJS.AES.encrypt(
      this.state.password,
      passKey
    ).toString();
    const userName = this.state.email.split('@');
    const urserNameFinal = userName[0].replace(/[^a-zA-Z0-9 ]/g, '');
    let data = {
      userName: urserNameFinal,
      password: ciphertext,
      email: this.state.email,
      rol: this.state.rol,
    };

    LoginService.create(data)
      .then(() => {
        this.setState({ open: true })
          setTimeout(() => {
            window.location = "/login";
          }, 2500);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    return (
      <Container component="main" maxWidth="xs">
        <div className="form-container">
          <Avatar className="avatar">
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Registro
          </Typography>
          <div className="login-container">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  onChange={this.onChangeValues}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Contraseña"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={this.onChangeValues}
                />
              </Grid>
            </Grid>
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className="button__save"
              onClick={this.onSaveUser}
            >
              Registrar
            </Button>
            <Grid container justify="center">
              <Grid item>
                <Link href="/login" variant="body2">
                  Ya tienes una cuenta? Iniciar sesión
                </Link>
              </Grid>
            </Grid>
          </div>
        </div>
        <Snackbar open={this.state.open} autoHideDuration={2000} onClose={this.handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
          <Alert onClose={this.handleClose} severity="success" sx={{ width: '100%' }}>
            Registrado correctamente!
          </Alert>
        </Snackbar>
        <Box mt={5}>
          <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit">TopTec</Link> {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </Container>
    );
  }
}
